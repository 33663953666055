/**
 * @component AccordionBehavior
 * @description Manages animated accordion functionality
 */
class AccordionBehavior {
    static config = {
        animation: {
            duration: 300,
            easing: 'ease-out',
        },
        selectors: {
            content: '.js-accordionItem__content',
            item: 'details.accordionList__item',
        },
        attributes: {
            expanded: 'aria-expanded',
            initialized: 'data-accordion-initialized',
        },
    };

    /**
     * @param {HTMLDetailsElement} element
     */
    constructor(element) {
        this.details = element;
        this.summary = element.querySelector('summary');
        this.content = element.querySelector(AccordionBehavior.config.selectors.content);

        // Store heights to avoid repeated calculations
        this.heights = {
            summary: 0,
            content: 0,
        };

        this.animation = null;
        this.state = {
            isClosing: false,
            isExpanding: false,
        };

        this.bindEvents();
        this.cacheHeights();
        this.markAsInitialized();
    }

    bindEvents() {
        // Use bound method for easier cleanup
        this.handleClick = this.onClick.bind(this);
        this.summary.addEventListener('click', this.handleClick);
    }

    /**
     * Cache height calculations to avoid layout thrashing
     */
    cacheHeights() {
        // Use RAF to batch reads
        requestAnimationFrame(() => {
            this.heights = {
                summary: this.summary.offsetHeight,
                content: this.content.offsetHeight,
            };
        });
    }

    markAsInitialized() {
        this.details.setAttribute(AccordionBehavior.config.attributes.initialized, 'true');
    }

    /**
     * @param {Event} event
     */
    onClick(event) {
        event.preventDefault();

        const isOpen = this.details.open;
        this.details.setAttribute(AccordionBehavior.config.attributes.expanded, (!isOpen).toString());

        if (this.state.isClosing || !isOpen) {
            this.open();
        } else if (this.state.isExpanding || isOpen) {
            this.shrink();
        }
    }

    shrink() {
        this.state.isClosing = true;

        const startHeight = `${this.details.offsetHeight}px`;
        const endHeight = `${this.heights.summary}px`;

        this.details.setAttribute(AccordionBehavior.config.attributes.expanded, 'false');
        this.animateHeight(startHeight, endHeight, false);
    }

    open() {
        // Batch DOM reads and writes
        requestAnimationFrame(() => {
            this.details.style.height = `${this.details.offsetHeight}px`;
            this.details.open = true;
            this.details.setAttribute(AccordionBehavior.config.attributes.expanded, 'true');

            this.expand();
        });
    }

    expand() {
        this.state.isExpanding = true;

        const startHeight = `${this.details.offsetHeight}px`;
        const endHeight = `${this.heights.summary + this.heights.content}px`;

        this.animateHeight(startHeight, endHeight, true);
    }

    /**
     * @param {string} startHeight
     * @param {string} endHeight
     * @param {boolean} isOpening
     */
    animateHeight(startHeight, endHeight, isOpening) {
        // Cancel any existing animation
        this.animation?.cancel();

        this.animation = this.details.animate(
            {height: [startHeight, endHeight]},
            AccordionBehavior.config.animation
        );

        this.animation.onfinish = () => this.onAnimationFinish(isOpening);
        this.animation.oncancel = () => {
            this.state.isClosing = false;
            this.state.isExpanding = false;
        };
    }

    onAnimationFinish(open) {
        this.details.open = open;
        this.animation = null;
        this.state = {isClosing: false, isExpanding: false};
        this.details.style.height = '';
    }

    destroy() {
        this.summary.removeEventListener('click', this.handleClick);
        this.animation?.cancel();
    }
}

class AccordionList extends HTMLElement {
    static observedAttributes = ['data-observe-resize'];

    constructor() {
        super();
        this.accordions = new WeakMap();
        this.resizeObserver = null;
    }

    connectedCallback() {
        this.setupResizeObserver();
        this.addEventListener('click', this.handleClick.bind(this));
    }

    /** Called each time the element is removed from the document. */
    disconnectedCallback() {
        this.resizeObserver?.disconnect();
        // WeakMap will handle cleanup of accordion instances
    }

    setupResizeObserver() {
        if (!this.hasAttribute('data-observe-resize')) return;

        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                const accordion = this.accordions.get(entry.target);
                accordion?.cacheHeights();
            }
        });
    }

    /**
     * @param {Event} event
     */
    handleClick(event) {
        const details = event.target.closest(AccordionBehavior.config.selectors.item);
        if (!details || details.hasAttribute(AccordionBehavior.config.attributes.initialized)) return;

        const accordion = new AccordionBehavior(details);
        this.accordions.set(details, accordion);

        if (this.resizeObserver) {
            this.resizeObserver.observe(details);
        }

        // Re-trigger click after initialization
        details.querySelector('summary')?.click();
    }
}

customElements.define('accordion-list', AccordionList);
